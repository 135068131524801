import Layout from "../Component/Layout/Layout";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import logo from '../logo.webp';
import logo2 from '../logo2.webp'
import logo3 from '../logo3.webp'
import logo4 from '../logo4.webp'
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios';
import Loader from "../Component/Loader";
import { URL } from "./BookingPage";


function Home() {
    const [name, setName] = useState("")
    const [modalShow, setModalShow] = useState(false);
    const [modalShowForOneWayCab, setModalShowForOneWayCab] = useState(false);
    const [modalShowForRoundTrip, setModalShowForRoundTrip] = useState(false);
    const [spanDisplay, setSpanDisplay] = useState("none");
    const [rideType, setRideType] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [date, setDate] = useState("");
    const [pickUpLocation, setPickUpLocation] = useState(null);
    const [pickUpAddress, setPickUpAddress] = useState("");
    const [dropOffAddress, setDropOffAddress] = useState("");
    const [DropOffLocation, setDropOffLocation] = useState(null);
    const [noOfPassangers, setNoOfPassangers] = useState(null);
    const [noOfChild, setNoOfChild] = useState({});
    const [noOfBags, setNoOfBags] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState("");
    const [selectedCar, setSelectedCar] = useState("");
    const [km, setKm] = useState("");
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const noOfPassangerData = [
        { id: 1, name: "1" }
    ]
    const noOfBagData = [
        { id: 1, name: "1" }
    ]

    const locationsData = [
        { id: 1, name: "ISBT - AGRA" },
        { id: 2, name: "ISBT - SARAI KALE KHAN (DELHI)" },
        { id: 3, name: "BHAGWAN TALKIES - (AGRA)" },
        { id: 4, name: "INDRAPRASHATA METRO STATION (DELHI)" },
    ]


    // data for one way cab
    const carData = [
        { id: 1, name: "HATCHBACK" },
        { id: 2, name: "SEDAN" },
        { id: 3, name: "SUV'S" },
    ]


    const passengerDataForSedanAndHatchBack = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
    ]
    const childDataForOneWay = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 0, name: "0" },
    ]
    const locationDataForOneWayCab = [
        { id: 1, name: "ISBT - (AGRA)" },
        { id: 2, name: "BHAGWAN TALKIES - (AGRA)" },
        { id: 3, name: "SIKANDRA - (AGRA)" },
        { id: 4, name: "AGRA" },
        { id: 5, name: "ISBT - SARAI KALE KHAN (DELHI)" },
        { id: 6, name: "NEW RAILWAY STATION - (DELHI)" },
        { id: 7, name: "IGI AIRPORT - (DELHI)" },
        { id: 8, name: "IGI T1 - (DELHI)" },
        { id: 9, name: "IGI T2 - (DELHI)" },
        { id: 10, name: "IGI T3 - (DELHI)" },
        { id: 11, name: "DELHI" },
    ]


    const bagDataForSedanAndSuv = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 0, name: "0" },
    ]
    // data for sedan
    const vehicleDataForSidan = [
        { id: 1, name: "HONDA AMAZE" },
        { id: 2, name: "SWIFT DZIRE" },
        { id: 3, name: "TOYOTA ETIOS" },
    ]

    // data for hatchback
    const bagDataForHatchback = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 0, name: "0" },
    ]
    const vehicleDataForHatchback = [
        { id: 1, name: "WAGONR" },
        { id: 2, name: "HYUNDAI EON" },
        { id: 3, name: "TOYOTA ETIOS" },
    ]

    // data for suv

    const vehicleDataForSuv = [
        { id: 1, name: "ERTIGA" },
        { id: 2, name: "INNOVA" },
        { id: 3, name: "RENAULT TRIBER" },
        { id: 4, name: "INNOVA CRYSTA" },
    ]
    const passengerDataForSuv = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
        { id: 6, name: "6" },
    ]


    // data for the round trip 
    const passangerDataForRoundTrip = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
        { id: 6, name: "6" },
    ]

    const childDataForRoundTrip = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 0, name: "0" },
    ]

    const bagDataForRoundTrip = [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 0, name: "0" },
    ]

    const vechileDataForRoundTrip = [
        { id: 1, name: "INNOVA CRYSTA - Rs. 17Km " },
        { id: 2, name: "ERTIGA (DIESEL - Rs. 14Km)" },
        { id: 3, name: "ERTIGA (CNG - Rs. 13Km)" },
        { id: 4, name: "RENAULT TRIBER (CNG - Rs. 12Km)" },
        { id: 5, name: "SWIFT DZIRE (DIESEL - Rs. 11Km)" },
        { id: 6, name: "SWIFT DZIRE (CNG - Rs. 10Km)" },
        { id: 7, name: "HONDA AMAZE (DIESEL - Rs. 11Km)" },
        { id: 8, name: "TOYOTA ETIOS (DIESEL - Rs. 11Km)" },
        { id: 9, name: "WAGONR (CNG - Rs. 9Km)" },
        { id: 10, name: "HYUNDAI EON (CNG - Rs. 9Km)" },
    ]



    const TAXI_SHARING = "Taxi Sharing (Agra = Delhi)";
    const ONE_WAY_CAB = "One Way Cab";
    const ROUND_TRIP = "Round Trip";


    const handleTaxiSharing = () => {
        navigate("/booking-page-taxi-sharing");
        setRideType(TAXI_SHARING);
        setModalShow(true);
    }

    const handleOneWayCab = () => {
        navigate("/booking-page")
        setRideType(ONE_WAY_CAB);
        setModalShowForOneWayCab(true);
    }

    const handleRoundTrip = () => {
        navigate("/booking-page-round-trip")
        setRideType(ROUND_TRIP);
        setModalShowForRoundTrip(true);
    }



    const handleBooking = async () => {

        try {
            if (!name || !email || !number || !pickUpLocation || !DropOffLocation || !date || !noOfPassangers || !noOfBags) {
                setSpanDisplay("block");
            }
            else if (!/^\d{10}$|^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(number)) {
                toast.error("Invalid phone number format");
            }
            else {
                setLoader(true);
                let message = `
                                <p><strong>Name:</strong> ${name}</p>
                                <p><strong>Email:</strong> ${email}</p>
                                <p><strong>Phone Number:</strong> ${number}</p>
                                <p><strong>Phone Date:</strong> ${date}</p>
                                <p><strong>Phone No Of Pasangers:</strong> ${noOfPassangers.name}</p>
                                <p><strong>Phone No Of Bags:</strong> ${noOfBags.name}</p>
                                <p><strong>Pick-up Address:</strong> ${pickUpLocation.name}</p>
                                <p><strong>Drop-off Address:</strong> ${DropOffLocation.name}</p>
                                <p><strong>Cab Type:</strong> Taxi Sharing </p>
                                <p><strong>Total Payment:</strong> 400 Per Passanger </p>
                                `

                const { data } = await axios.post(URL, {
                    name,
                    email,
                    message
                });
                if (data?.success) {
                    toast.success("Booking Confirmed");
                    setModalShow(false);
                    setLoader(false);
                }
                else {
                    setLoader(false);
                    toast.error("Something went wrong please try again later.");
                }
            }

        } catch (error) {
            setLoader(false);
            console.error('Error sending email:', error);
            toast.error("Something went wrong")
        }
    }

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const [minDateTime, setMinDateTime] = useState(getCurrentDateTime);

    useEffect(() => {
        const interval = setInterval(() => {
            setMinDateTime(getCurrentDateTime());
        }, 1000); // Update minDateTime every second

        return () => clearInterval(interval);
    }, []);

    const handleDateTimeChange = (e) => {
        const selectedDateTime = e.target.value;
        const now = new Date();
        const selectedDate = new Date(selectedDateTime);

        if (selectedDate < now) {
            toast.error('Cannot select a past date and time.');
            setDate('');
        } else {
            setDate(selectedDateTime);
        }
    };


    return (
        <>
            {loader ? (
                <Loader />
            ) : ("")}
            <div className="App">
                <Layout>
                    <Container className="main-content my-5">
                        <h1 className="text-center" style={{ textDecoration: "underline", color: "#F8BD26", fontSize: "4rem" }}>Our Services</h1>
                        <Row className="mt-5 d-flex justify-content-center">
                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex justify-content-center">
                                <Card style={{ width: '15rem' }}>
                                    <Card.Img variant="top" src={logo2} />
                                    <Card.Body>
                                        <Card.Title>Taxi Sharing</Card.Title>
                                        <Card.Text style={{ fontWeight: "bold" }}>
                                            (Agra = Delhi)
                                        </Card.Text>
                                        <Button variant="warning" onClick={handleTaxiSharing}>Book Now</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex justify-content-center">
                                <Card style={{ width: '15rem' }}>
                                    <Card.Img variant="top" src={logo3} />
                                    <Card.Body>
                                        <Card.Title>One Way Cab</Card.Title>
                                        <Card.Text style={{ fontWeight: "bold" }}>
                                            (All India)
                                        </Card.Text>
                                        <Button variant="warning" onClick={handleOneWayCab}>Book Now</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex justify-content-center">
                                <Card style={{ width: '15rem' }}>
                                    <Card.Img variant="top" src={logo4} />
                                    <Card.Body>
                                        <Card.Title>Round Trip</Card.Title>
                                        <Card.Text style={{ fontWeight: "bold" }}>
                                            (All India)
                                        </Card.Text>
                                        <Button variant="warning" onClick={handleRoundTrip}>Book Now</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Layout>
            </div>

            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Book Your Cab -- {rideType}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            Name
                        </label>
                        <div className="col-md-10">
                            <input type="text"
                                className='form-control'
                                placeholder="Enter Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)} />
                            {!name && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            Mobile Number
                        </label>
                        <div className="col-md-10">
                            <input type="number"
                                className='form-control'
                                placeholder="Mobile Number"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)} />
                            {!number && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            Email
                        </label>
                        <div className="col-md-10">
                            <input type="text"
                                className='form-control'
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                            {!email && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                        </div>
                    </Row>

                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            No of Passenger
                        </label>
                        <div className="col-md-10">
                            <Select

                                value={noOfPassangers}
                                onChange={(e) => setNoOfPassangers(e)}
                                options={noOfPassangerData}
                                getOptionLabel={option => option?.name}
                                getOptionValue={option => option?.name?.toString()}
                                classNamePrefix="select2-selection"
                            />
                            {!noOfPassangers && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            No of Bag
                        </label>
                        <div className="col-md-10">
                            <Select

                                value={noOfBags}
                                onChange={(e) => setNoOfBags(e)}
                                options={noOfBagData}
                                getOptionLabel={option => option?.name}
                                getOptionValue={option => option?.name?.toString()}
                                classNamePrefix="select2-selection"
                            />
                            {!noOfBags && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}
                        </div>
                    </Row>







                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" color={loader ? "secondary" : "primary"} onClick={() => setModalShow(false)} className="waves-effect waves-light" disabled={loader}>Close</Button>{" "}
                    <Button type="button" color={loader ? "secondary" : "success"} onClick={handleBooking} className="waves-effect waves-light" disabled={loader}>Book Now</Button>{" "}

                </Modal.Footer>
            </Modal>


        </>
    )
}

export default Home;