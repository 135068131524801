import React, { useState } from 'react'
import Layout from '../Component/Layout/Layout';
import { Button, Col, Container, Modal, Row, Toast } from 'react-bootstrap';
import Select, { components } from "react-select"
import sedan from "../images/sedan_new.png";
import suv from "../images/suv_new.png";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../Component/Loader';
export const URL = "https://rstravel.in/send-email";
const BookingSharingTaxi = () => {
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [km, setKm] = useState("0");
    const [show, setShow] = useState(false);
    const [carData, setCarData] = useState([]);
    const [first, setFirst] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [pickUpAddress, setPickupAddress] = useState("");
    const [dropOffAddress, setDropOffAddress] = useState("");
    const [spanDisplay, setSpanDisplay] = useState("none");
    const [paymentType, setPaymentType] = useState("");
    const [price, setPrice] = useState("");
    const [departure, setDeparture] = useState("");
    const [time, setTime] = useState("");
    const [spanDisplay2, setSpanDisplay2] = useState("none");
    const [selectedCar, setSelectedCar] = useState("");
    const [selectedCarType, setSelectedCarType] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [DropOffLocation, setDropOffLocation] = useState(null);
    const [noOfPassangers, setNoOfPassangers] = useState(null);
    const [pickUpLocation, setPickUpLocation] = useState(null);
    const [noOfBags, setNoOfBags] = useState(null);
    const [loader, setLoader] = useState(false);
    const [date, setDate] = useState("");
    const locationsData = [
        { id: 1, name: "ISBT - AGRA" },
        { id: 2, name: "ISBT - SARAI KALE KHAN (DELHI)" },
        { id: 3, name: "BHAGWAN TALKIES - (AGRA)" },
        { id: 4, name: "INDRAPRASHATA METRO STATION (DELHI)" },
    ]

    const noOfPassangerData = [
        { id: 1, name: "1" }
    ]
    const noOfBagData = [
        { id: 1, name: "1" }
    ]




    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: '5rem',
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
            paddingTop: '1.5rem', // Added padding to ensure the label and the selected option do not overlap
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '100%', // Ensures that the value container also takes the full height
            display: 'flex',
            alignItems: 'center', // Centers the text vertically
            paddingTop: '1rem', // Added padding to push the selected option below the label
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'gray', // Optional: Customize placeholder text color
        }),
        singleValue: (provided) => ({
            ...provided,
            marginTop: '0.5rem', // Adjust the margin to align selected value properly
        }),
    };

    const CustomControl = ({ children, ...props }) => (
        <components.Control {...props}>
            <label
                htmlFor="From"
                style={{
                    position: 'absolute',
                    left: '10px',
                    top: '0.5rem', // Position the label above the selected option
                    pointerEvents: 'none',
                    color: '#999',
                    fontSize: '1rem',
                }}
            >
                {props.selectProps.label}
            </label>
            {children}
        </components.Control>
    );



    const handleSearch = () => {


        if (!from || !to || !departure || !time) {
            setSpanDisplay2("block")
        }
        else if (from.name === to.name) {
            toast.error("From and Two shoud not be same")

            setShow(false);
        }
        else {
            setFirst(false);
            setShow(true);


        }

    }

    const handleBooking = async () => {
        try {
            if (!name || !email || !number || !noOfPassangers || !noOfBags) {
                setSpanDisplay("block");
            }
            else if (!/^\d{10}$|^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(number)) {
                toast.error("Invalid phone number format");
            }
            else {
                setLoader(true);
                let message = `
                                <p><strong>Name:</strong> ${name}</p>
                                <p><strong>Email:</strong> ${email}</p>
                                <p><strong>Phone Number:</strong> ${number}</p>
                                <p><strong>Date :</strong> ${departure}</p>
                                <p><strong>Time :</strong> ${time}</p>
                                <p><strong>Phone No Of Pasangers:</strong> ${noOfPassangers.name}</p>
                                <p><strong>Phone No Of Bags:</strong> ${noOfBags.name}</p>
                                <p><strong>Pick-up Address:</strong> ${from.name}</p>
                                <p><strong>Drop-off Address:</strong> ${to.name}</p>
                                <p><strong>Cab Type:</strong> Taxi Sharing </p>
                                <p><strong>Total Payment:</strong> 400 Per Passanger </p>
                                `

                console.log(message);
                const { data } = await axios.post(URL, {
                    name,
                    email,
                    message
                });
                if (data?.success) {
                    toast.success("Booking Confirmed");
                    setModalShow(false);
                    setLoader(false);
                }
                else {
                    setLoader(false);
                    toast.error("Something went wrong please try again later.");
                }
            }

        } catch (error) {
            setLoader(false);
            console.error('Error sending email:', error);
            toast.error("Something went wrong")
        }
    }

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);

    };

    const handleBookNow = (d) => {
        setModalShow(true);
        setPrice(400);
    }
    return (
        <>
            <Layout>
                {loader ? (
                    <Loader />
                ) : ("")}
                <Container className="main-content my-5">
                    <div className="" style={{ background: "white", borderRadius: "1rem", padding: "1rem", color: "black", position: "sticky", }}>
                        <Row>
                            <Col xs={12} sm={6} md={4} lg={4} >

                                <div class="form-group mt-4">

                                    <Select
                                        value={from}
                                        onChange={(e) => setFrom(e)}
                                        options={locationsData}
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.name?.toString()}
                                        classNamePrefix="select2-selection"
                                        styles={customStyles}
                                        components={{ Control: CustomControl }}
                                        label="From"
                                        placeholder=""

                                    />
                                    {!from && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}

                                </div>


                            </Col>
                            <Col xs={12} sm={6} md={4} lg={4} >

                                <div class="form-group mt-4">

                                    <Select
                                        value={to}
                                        onChange={(e) => setTo(e)}
                                        options={locationsData}
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.name?.toString()}
                                        classNamePrefix="select2-selection"
                                        styles={customStyles}
                                        components={{ Control: CustomControl }}
                                        label="To"
                                        placeholder=""

                                    />
                                    {!to && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}

                                </div>


                            </Col>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <div className="form-group mt-4" style={{ position: "relative" }}>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="From1" // Use unique IDs for each input and label
                                        value={departure}
                                        onChange={(e) => setDeparture(e.target.value)}
                                        style={{
                                            height: "5rem",
                                            width: "100%", // Ensure input takes full width
                                            paddingLeft: "10px", // Adjust padding as needed
                                        }}

                                    />
                                    {!departure && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}
                                    <label
                                        htmlFor="From1"
                                        style={{
                                            position: "absolute",
                                            top: "-1rem", // Adjust to position label at desired height
                                            left: "10px",
                                            backgroundColor: "white", // Ensure label background is same as input
                                            padding: "0 5px",
                                            color: "#aaa",
                                        }}
                                    >
                                        Departure
                                    </label>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <div className="form-group mt-4" style={{ position: "relative" }}>
                                    <input
                                        className="form-control"
                                        type="time"
                                        id="From2" // Use unique IDs for each input and label
                                        value={time}
                                        onChange={(e) => setTime(e.target.value)}
                                        style={{
                                            height: "5rem",
                                            width: "100%", // Ensure input takes full width
                                            paddingLeft: "10px", // Adjust padding as needed
                                        }}
                                    />
                                    {!time && (
                                        <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.2rem", display: spanDisplay2 }}>
                                            This field is required.
                                        </p>
                                    )}
                                    <label
                                        htmlFor="From2"
                                        style={{
                                            position: "absolute",
                                            top: "-1rem", // Adjust to position label at desired height
                                            left: "10px",
                                            backgroundColor: "white", // Ensure label background is same as input
                                            padding: "0 5px",
                                            color: "#aaa",
                                        }}
                                    >
                                        Pick Up Time
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mt-3 m-auto d-flex justify-content-end">
                                    <Button style={{
                                        background: "linear-gradient(93deg, #53b2fe, #065af3)",
                                        backgroundColor: "var(--color-btn-primary-bg)",
                                        color: "white",
                                        fontWeight: "bolder"
                                    }}
                                        onClick={handleSearch}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {show ?
                        <>
                            <div className="mt-5">
                                <div className="mt-2 ">
                                    <div className="cab-card">
                                        <Row>
                                            <Col xs={2} sm={2} md={2} lg={2}>
                                                <div className="m-sm-3">
                                                    <img src={sedan} alt="" width="100" />
                                                </div>
                                            </Col>
                                            <Col xs={7} sm={7} md={7} lg={8}>
                                                <div className="" style={{ color: "black" }}>
                                                    <div className="d-flex head">
                                                        <h3>Any Taxi</h3>
                                                    </div>

                                                    <div className="">
                                                        <ul className='d-flex f-wrap' style={{ flexWrap: "wrap" }}>
                                                            <li className='me-4'>Hatchback</li>
                                                            <li className='me-4'>Ac</li>
                                                            <li className='me-4'>4 Seats</li>
                                                        </ul>
                                                    </div>
                                                    <div className="">
                                                        <h4>
                                                            Spacious
                                                        </h4>
                                                    </div>
                                                    <div className="w-md-75 w-sm-100 w-xs-100">
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6 className=''><AddLocationAltIcon className="icon" fontSize="small" color="primary" /> Extra km fare </h6>
                                                            <h6>₹10/km after {km} kms</h6>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6 className=''><LocalGasStationIcon className="icon" fontSize="small" color="primary" /> Fuel type </h6>
                                                            <h6>CNG</h6>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6 className=''><CancelIcon className="icon" fontSize="small" color="primary" />Cancellation </h6>
                                                            <h6><span style={{ color: "green" }}>Free</span> till 1 hour of departure</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={2}>
                                                <div className="mt-3" style={{ color: "black" }}>
                                                    <h3 className='price' >₹ 400 </h3>
                                                    <p>(All taxes and charges included)</p>
                                                    <div className="mt-3">
                                                        <Button className="button" style={{
                                                            background: "linear-gradient(93deg, #53b2fe, #065af3)",
                                                            backgroundColor: "var(--color-btn-primary-bg)",
                                                            color: "white",
                                                            fontWeight: "bolder",
                                                            borderRadius: "1.5rem"
                                                        }}
                                                            onClick={() => handleBookNow()}
                                                        >
                                                            Book Now
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>


                            </div>
                        </>
                        :
                        <>
                            {!first &&
                                <div className="m-auto mt-5 text-center">
                                    <h3>Not Available</h3>
                                </div>}
                        </>}
                </Container>
                <Modal
                    show={modalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Book Your Cab
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Name
                            </label>
                            <div className="col-md-10">
                                <input type="text"
                                    className='form-control'
                                    placeholder="Enter Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                                {!name && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Mobile Number
                            </label>
                            <div className="col-md-10">
                                <input type="number"
                                    className='form-control'
                                    placeholder="Mobile Number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)} />
                                {!number && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Email
                            </label>
                            <div className="col-md-10">
                                <input type="text"
                                    className='form-control'
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                {!email && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>

                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                No of Passenger
                            </label>
                            <div className="col-md-10">
                                <Select

                                    value={noOfPassangers}
                                    onChange={(e) => setNoOfPassangers(e)}
                                    options={noOfPassangerData}
                                    getOptionLabel={option => option?.name}
                                    getOptionValue={option => option?.name?.toString()}
                                    classNamePrefix="select2-selection"
                                />
                                {!noOfPassangers && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}

                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                No of Bag
                            </label>
                            <div className="col-md-10">
                                <Select

                                    value={noOfBags}
                                    onChange={(e) => setNoOfBags(e)}
                                    options={noOfBagData}
                                    getOptionLabel={option => option?.name}
                                    getOptionValue={option => option?.name?.toString()}
                                    classNamePrefix="select2-selection"
                                />
                                {!noOfBags && <span style={{ color: "red", display: spanDisplay }}>This feild is required</span>}
                            </div>
                        </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" color={loader ? "secondary" : "primary"} onClick={() => setModalShow(false)} className="waves-effect waves-light" disabled={loader}>Close</Button>{" "}
                        <Button type="button" color={loader ? "secondary" : "success"} onClick={handleBooking} className="waves-effect waves-light" disabled={loader}>Book Now</Button>{" "}

                    </Modal.Footer>
                </Modal>
            </Layout>
        </>
    )
}

export default BookingSharingTaxi;
