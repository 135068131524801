import logo from './logo.svg';
import './App.css';
import Header from './Component/Layout/Header';
import Footer from './Component/Layout/Footer';
import Layout from './Component/Layout/Layout';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import BookingPage from './pages/BookingPage';
import BookingPageRoundTrip from './pages/BookingPageRoundTrip';
// Toast for Notification
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import BookingSharingTaxi from './pages/BookingSharingTaxi';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/booking-page' element={<BookingPage />} />
        <Route exact path='/booking-page-round-trip' element={<BookingPageRoundTrip />} />
        <Route exact path='//booking-page-taxi-sharing' element={<BookingSharingTaxi />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
